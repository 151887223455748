import React from "react"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
 } from "../components/reactPlayer"
const fixture = [
    {
        programName: "Your Miraculous Life Club",
        type: 'spoon-therapy',
        featuredImage: "/images/spoon-therapy-min.jpg",
        audioFiles: [            
            {
                title: 'Your Miraculous Life Club - Session 1',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 1',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-session-1.mp4"
                                    title="Session 1"
                                />,
                            }
                        ],
                    },
                ]
            },
            {
                title: 'Your Miraculous Life Club - Session 2',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 2',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-session-2.mp4"
                                    title="Session 2"
                                />,
                            }
                        ],
                    },
                ]
            },
            {
                title: 'Your Miraculous Life Club - Session 3',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 3',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-session-3.mp4"
                                    title="Session 3"
                                />,
                            }
                        ],
                    },
                ]
            },
            {
                title: 'Your Miraculous Life Club - Session 4',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-min.png",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 4',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/ymlc/your-miraculous-life-club-session-4.mp4"
                                    title="Session 4"
                                />,
                            }
                        ],
                    },
                ]
            }
        ],
    }
]
  
  export default fixture
  




// sound-bell.jpg